import axios from "axios";
import { useEffect, useState } from "react";
import EndPoint from "../../api/Endpoint";
import { Button, Form } from "react-bootstrap";
import { useParams,redirect } from "react-router-dom";
import logo from "../../assets/img/hom-login-large.png";
import Constants from "../../api/Constants";

const ResetPassword = (prop) => {
  // const { token3 } = useParams();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  
  
  
  const [user, setUser] = useState({
    token: token,
    confirm_password: "",
    password: "",
  });
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });

  const onChangeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  //Ch019@test.com
  const onSubmit = () => {
    axios
      .post(EndPoint.RESET_PASSWORD_URL, user)
      .then(
        (response) => {
          console.log("success", response);
          setResponce({
            show: true,
            message: "Reset Password Successfully",
            type: "success",
          });
          setUser({ confirm_password: "", password: "" });
          setTimeout(() => {
            window.location.href = (`${EndPoint.FRONT_LOGIN_URL}`);
          }, 800);
        },
        (error) => {
          console.log(error.response.data.message);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => console.log(e));
  };
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="main">
      <section className="signup">
        <div className="container">
          <h2 className="text-center logo-img mb-30">
            <img src={logo} />
          </h2>
          <h3 className="text-center ">Reset Password</h3>
          <div className="signup-content">
            {responseObj.show ? (
              <div
                className={
                  responseObj.type == "success"
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {responseObj.message}
              </div>
            ) : (
              ""
            )} 
            {localStorage.getItem("token") != null?(<div className="text-center">You are already logged in. Please Logout and then try.</div>):(
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={onChangeHandler}
                  placeholder="New Password"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  name="confirm_password"
            value={user.confirm_password}
            onChange={onChangeHandler}
                  placeholder="Confirm New Password"
                />
              </Form.Group>

              <Button
                className="full-width"
                variant="primary"
                onClick={onSubmit}
                type="button"
              >
                Update
              </Button>
            </Form>
            ) }
          </div>
        </div>
      </section>
      <footer className="footer-section">
        <div className="container">
          © {year} DISH Wireless L.L.C. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default ResetPassword;
